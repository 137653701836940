.list_container {
    display: flex;
    flex-direction: column;
}

.sign_out_button {
    display: none;
}

.list_item {
    display: flex;
    width: 100%;
    padding: 5px 10px 5px 10px;
    text-align: center;
}

.logout_img {
    max-width: 300px;
    height: auto;
}

.main_container {
    background-image: linear-gradient(#17AAC5, #77CCBF);
    border-radius: 10px;
    color: white;
}

.footer {
    align-self: flex-start;
    margin-left: 20px;
    padding-bottom: 10px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.list_item {
    display: flex;
    width: 100%;
    background-color: transparent;
    text-align: left
}

.list_container {
    flex-direction: column;
    border: none;
}

.main_container {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#17AAC5, #77CCBF);
    border-radius: 10px;
    color: white;
    height: 90vh;
}

.list_item {
    align-self: center;
    border-radius: 10px;
}

.popup_container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign_out_button {
    color: white;
    border-color: white;
    border-radius: 500px;
    margin-bottom: 10px;
    font-weight: bold;
    display: block;
}

.footer {
    align-self: flex-start;
    margin-left: 20px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text {
    padding-top: 20px;
    font-weight: bold;
}

.button_container {
    display: flex;
    margin-top: 20px;
}

.button_style {
    color: white;
    align-self: center;
    background-color: #2AB1C3;
    border-radius: 22px;
    padding: 10px 40px 10px 40px;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.button_style_no {
    color: #2AB1C3;
    align-self: center;
    border: 1px solid #2AB1C3;
    background-color: #F6FEFF;
    border-radius: 22px;
    padding: 10px 40px 10px 40px;
    margin-left: -5%;
    margin-right: 10%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.cancel {
    margin-top: 10px;
    color: #2AB1C3;
    cursor: pointer;
}

.sign_out_button {
    font-weight: bold;
}

@media (max-width: 450px) {
    .list_container {
        flex-direction: column;
    }
}

@media (max-width: 800px) {
    .main_container {
        position: absolute;
    }
}

.appbar_container {
    display: flex;
    align-items: center;
}

.control_container{
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.logout_img {
    max-width: 300px;
    height: auto;
}

.sidemenu_icon {
    display: none;
    margin-left: 10px;
}

.popup_container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vet_photo {
    border-radius: 50%;
    margin-right: 10px;
    height: 40px;
    max-width: 40px;
}

@media (max-width: 430px) {
    .vet_name {
        display: none;
    }
}

.text {
    padding-top: 20px;
    font-weight: bold;
}

.button_container {
    display: flex;
    margin-top: 20px;
}

.button_style {
    color: white;
    align-self: center;
    background-color: #2AB1C3;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.button_style_no {
    color: #2AB1C3;
    align-self: center;
    border: 1px solid #2AB1C3;
    background-color: #F6FEFF;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.cancel {
    margin-top: 10px;
    color: #2AB1C3;
    cursor: pointer;
}

.page_title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
}

@media (max-width: 800px) {
    .sidemenu_icon {
        display: block;
    }
}

.main_container {
    border-radius: 6px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.card_container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 3%;
}

.photo_container {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 3%;
    z-index: 0;
}

.pet_photo {
    border-radius: 50%;
    position: relative;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    overflow: hidden;
    z-index: 0;
}

.parent_photo {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 30px;
    height: 25px;
    width: 25px;
    overflow: hidden;
    z-index: 0;
}

.name_time_div {
    margin-right: 15%;
}

.pet_parent_name_div {
    margin-bottom: 1%;
    font-weight: bold;
}

.issue_div {
    margin-right: 5%;
}

.issue_title_div {
    margin-bottom: 1%;
    font-weight: 300;
    color: #8F9BB3;
}

.status_div {
    position: relative;
    z-index: 0;
    top: -150px;
    margin-right: 10%;
    margin-left: auto;
}

.status_title_div {
    font-weight: bold;
    margin-bottom: 1%;
    padding-left: 5px;
    padding-right: 5px;
}

@media (min-width: 800px) {
    .card_container {
        flex-direction: row;
        padding-bottom: 20px;
    }

    .photo_container {
        align-self: center;
    }

    .name_time_div {
        align-self: center;
    }

    .issue_div {
        align-self: center;
    }

    .issue_title_div {
        border-top: 0px;
    }

    .status_div {
        align-self: center;
        position: relative;
        top: 0px;
    }
}
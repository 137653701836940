.main_container {
    padding-top: 50px;
    padding-left: 15%;
    width: 80%;
    height: 50vh;
}


.back_button_container {
    margin-bottom: 20px;
}


.back_button_div {
    display: inline-flex;
    font-weight: bold;
}

.details_container {
    display: flex;
    height: 100%;
}

.name_card {
    width: 25%;
    margin-right: 3%;
}

.paper {
    border-radius: 6px;
}

.pet_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.pet_image {
    border-radius: 50%;
    margin-bottom: 10px;
    height: 50px;
    width: 50px;
    overflow: hidden;
}

.pet_name_container {
    margin-top: 30px;
    margin-bottom: 10px;
}

.parent_container {
    padding-top: 5px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.parent_image {
    border-radius: 50%;
    margin-right: 3%;
    height: 30px;
    width: 30px;
    overflow: hidden
}

.data_container {
    width: 90%;
}

.data_paper {
    border-radius: 6px;
}

.styled_tabs {
    background-color: white;
    color: black;
}

.pet_data_container {
    display: flex;
    background-color: lightblue;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.health_paper {
    border-radius: 6px;
    margin-bottom: 15px;
}

.health_paper_container {
    display: flex;
    cursor: pointer;
}

.image_container {
    padding: 15px 0px 5px 15px;
}

.health_image {
    border-radius: 10px;
    height: 50px;
    width: 50px;
    overflow: hidden;
}

.health_data_container {
    align-self: center;
    margin-left: 2%;
}

.health_data_time {
    font-weight: 300;;
}

.past_session_paper {
    border-radius: 6px;
    margin-bottom: 15px;
}

.past_session_paper_container {
    display: flex;
    padding: 15px 0px 15px 0px;
}

.past_session_parent_image_container {
    padding: 15px 0px 5px 15px;
}

.past_session_parent_image {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    overflow: hidden;
}

.past_session_data_container {
    align-self: center;
    margin-left: 5%;
}

.past_session_data_span {
    font-weight: 300;
}

@media(max-width: 1000px) {
    .main_container {
        padding-top: 50px;
        padding-left: 5%;
        width: 85%;
        height: 50vh;
    }
}

@media (max-width: 800px) {

    .main_container {
        padding-top: 50px;
        /* padding-left: 3%; */
        width: 105%;
        height: 50vh;
    }

    .parent_container {
        padding-top: 5px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
    }

    .pet_details_container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .details_container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .name_card {
        margin-bottom: 50px;
        width: 90%;
    }

    .data_paper {
        margin-bottom: 50px;
    }
}

.vaccination_details_container {
    padding-left: 1%;
    padding-right: 0.5%;
    padding-bottom: 15px;
}

.vaccination_details_vaccination_report {
    width: 99%;
}

.vaccination_details_vaccination_report_title {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.vaccination_details_vaccination_report_title_uploaded {
    padding-top: 10px;
}

.vaccination_details_vaccination_report_description {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.vaccination_details_vaccination_report_description_text {
    padding-top: 10px;
    margin-right: 5px;
}

.vaccination_details_vaccination_report_title_title {
    font-weight: bold;
}

.vaccination_details_vaccination_report_description_title {
    font-weight: bold;
}

.main_container {
    border-radius: 6px;
    margin-bottom: 20px;
    margin-left: 5px;
    width: 500px;
    margin-bottom: 20px;
}

.case_card {
    display: flex;
    padding-top: 20px;
    padding-left: 5%;
    padding-bottom: 20px;
}

.photo_container {
    align-self: center;
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10%;
}

.pet_photo {
    border-radius: 50%;
    position: relative;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    overflow: hidden;
    z-index: 0;
}

.parent_photo {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 30px;
    height: 25px;
    width: 25px;
    overflow: hidden;
    z-index: 0;
}

.data_div {
    align-self: center;
    margin-right: 3%;
}

.name_div {
    margin-bottom: 1%;
    font-weight: bold;
}

.time_div {
    margin-bottom: 1%;
}

@media (max-width: 800px) {
    .main_container {
        width: auto;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

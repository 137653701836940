body {
    background-color: #F9F9F9;
}

.main_container {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
}

.sidebar_container {
    padding-bottom: 20px;
    width: 200px;
    z-index:100;
}

.sidebar {
    position: fixed;
    left: -100%;
    transition: left 850ms ease 0s;
}

.caselist_section {
    flex-grow: 3;
    padding-left: 15px;
}

.sidebar.active {
    left: auto;
    transition: left 350ms ease 0s;
}  

.progress_circle {
    position: absolute;
    left: 55%;
    top: 50%;
}

@media (max-width: 800px) {
    .sidebar.active {
        margin-top: 10px;
        margin-bottom: 10px;
        left: 10px;
    }  

    .progress_circle {
        left: 45%;
    }
}

@media (min-width: 800px) {
    .main_container {
        flex-direction: row;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .sidebar {
        position: relative;
    }
}
@font-face {
    font-family: 'Brandon Grotesque';
    src: local('Brandon Grotesque'), url(./Assets/fonts/brandon-grotesque-light.otf) format('opentype');
  }

  /* @font-face {
    font-family: 'Brandon Grotesque Black';
    src: local('Brandon Grotesque Black'), url(./Assets/fonts/brandon-grotesque-black.otf) format('opentype');
  } */

  * { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Brandon Grotesque';
} 
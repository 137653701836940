.fab {
    position: fixed;
    bottom: 30px;
    right: 50px;
}

.fab_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat_icon {
    width: 30%;
}

.support_img {
    max-width: 300px;
    height: auto;
}

.chat_text_span {
    font-size: 80%;
}

.popup_container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
}

.button_container {
    display: flex;
    margin-top: 10px;
}

.button_style {
    color: white;
    align-self: center;
    background-color: #2AB1C3;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.cancel {
    margin-top: 10px;
    color: #2AB1C3;
    cursor: pointer;
}
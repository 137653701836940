.auth_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30%;
}

.login {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 5px;
}

@media (min-width: 500px) {
    .main_container {
        background-image: url("../../Assets/auth_bg.png");
        background-color: #EEEDF0;
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: end;
        flex-direction: column;
    }

    .auth_container {
        align-self: flex-end;
        padding-right: 20%;
        padding-top: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logo {
        /* align */
    }

    .login {
        padding-top: 5%;
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 50%;
        padding-bottom: 5px;
        transform: translateX(-50%);
    }
}

.modal_content {
    width: 100%;
}

.mark_consult_complete_dialog_popup_container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mark_consult_complete_dialog_text {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bold;
}

.mark_consult_complete_dialog_button_container {
    display: flex;
    margin-top: 10px;
}

.mark_consult_complete_dialog_button_yes_style {
    color: white;
    align-self: center;
    background-color: #2AB1C3;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.mark_consult_complete_dialog_button_no_style {
    color: white;
    align-self: center;
    background-color: red;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}


.mark_consult_complete_dialog_cancel {
    margin-top: 10px;
    color: #2AB1C3;
    cursor: pointer;
}

.main_container {
    padding-top: 10px;
    padding-left: 5%;
    width: 95%;
    height: 80vh;
}

.pet_data_session_info_emergency_container {
    background-color: #FFF1F1;
    margin-left: -2%;
    margin-right: -2%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pet_data_session_info_emergency_bulb_img {
    width: 40px;
    margin-right: 10px;
}

.pet_data_session_info_img {
    border-radius: 50%;
    height: auto;
    height: 50px;
    width: 50px;
    overflow: hidden;
}

.doctors_notes_edit_button_div {
    display: flex;
    font-weight: bold;
    color: #2AB1C3;
    cursor: pointer;
    justify-content: flex-end;
}

.pet_details_video_call_container {
    border-top: 1px solid black;
    margin-bottom: 2%;
}

.pet_details_video_call_link_share {
    margin-right: 2%;
    margin-left: auto;
    cursor: pointer;
}

.pet_details_video_call_time {
    margin: 2%;
}

a, a:visited, a:hover, a:active {
    color: inherit;
}

.pet_details_video_call_start_call_div {
    display: flex;
    color: #2AB1C3;
    margin-left: 2%;
    margin-right: 2%;
}

.pet_data_session_info_name_div {
    margin-left: 10px;
}

.pet_data_session_info_name_name_span {
    font-weight: bold;
}

.pet_data_session_info_name_age_span {
    font-weight: 300;
}

.pet_data_session_info_name_issue_div {
    margin-top: 30px;
}

.pet_data_session_info_name_issue_span {
    font-weight: bold;
}

.pet_data_session_info_name_issue_title {
    font-weight: 300;
}

.pet_data_session_additional_details_div {
    margin-top: 30px;
}

.pet_data_session_additional_details_name {
    font-weight: bold;
}

.pet_data_session_additional_details_title {
    font-weight: 300;
}

.pet_data_session_media_div {
    margin-top: 30px;
}

.pet_data_session_media_span {
    font-weight: bold;
}

.pet_data_session_doctors_notes_container {
    white-space: pre-line;
    white-space: pre-wrap;
}

.back_button_container {
    display: flex;
    align-items: center;
}

.consult_id_text {
    justify-items: flex-end;
    margin-left: 10%;
}

.pet_container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pet_details_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.case_completed_button_style {
    color: white;
    align-self: center;
    background-color: #2AB1C3;
    border-radius: 22px;
    padding: 10px 10% 10px 10%;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}

.pet_data_session_follow_up_note_add_button {
    margin-left: auto;
    color: #2AB1C3;
    align-self: center;
    border: solid 1px #2AB1C3;
    border-radius: 22px;
    padding: 10px 2% 10px 2%;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}

.pet_details_paper {
    border-radius: 6px;
}

.pet_details_paper_container {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.pet_details_photo {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 20px;
}

.pet_details_pet_pic {
    border-radius: 50%;
    position: relative;
    top:0;
    left: 0;
    height: 50px;
    width: 50px;
    overflow: hidden;
    cursor: pointer;
}

.pet_details_parent_pic {
    border-radius: 50%;
    position: absolute;
    top:50%;
    left: 30px;
    height: 25px;
    width: 25px;
    overflow: hidden;
}

.pet_details_name {
    margin-top: 10px;
    margin-bottom: 5px;
}

.pet_details_name_span {
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
}

.pet_details_requested {
    margin-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
}

/* .pet_details_follow_up {
    color:orange;
    font-weight: bold;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 10px;
} */

.pet_details_video_button {
    margin-right: 5px;
    font-weight: bold;
}

.pet_details_allow_parent_text {
    font-weight: 300;
}

.pet_data_container {
    width: 100%;
    padding-bottom: 20px;
}

.pet_data_paper {
    border-radius: 6px;
    height: 100%;
}

.pet_data_paper_div {
    height: 88%;
    overflow: auto;
}

.pet_data_styled_tabs {
    background-color: white;
    color: black;
}

.pet_data_session_info_pet_data_container {
    display: flex;
}

.pet_data_session_follow_up_note_container {
    display: flex;
    align-items: center;
}

.doctor_note_add_button {
    color: #2AB1C3;
    align-self: center;
    border: 1px solid #2AB1C3;
    background-color: #F6FEFF;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 120px;
}

.add_prescription_photo_div {
    display:flex;
}

.add_prescription_photo_button_div {
    border: 1px dashed #2AB1C3;
    border-radius: 10px;
    display: "flex";
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.add_prescription_photo_button_image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px;
}

.add_prescription_photo_button_a {
    text-decoration: none;
}

.pet_details_allow_parent_container {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.image_upload_button {
    border: none;
    color: #fff;
    padding: 15px 30px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}

input[type='file'] {
    /* opacity:0; */
    position: absolute;
    display: none;
}

.pet_data_session_images {
    display: flex;
}

.image_viewer {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
}

.modal_content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    margin-bottom: 5%;
}
.modal_content {
    animation-name: zoom;
    animation-duration: 0.6s;
}
@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}
.image_viewer .image_viewer_close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
.image_viewer .image_viewer_close:hover,
.image_viewer .image_viewer_close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.image_viewer .image_viewer_delete {
    position: absolute;
    top: 30px;
    left: 35px;
    color: #f1f1f1;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
}
.image_viewer .image_viewer_delete:hover,
.image_viewer .image_viewer_delete:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.pet_data_session_single_image > img {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.pet_data_session_single_image > img:hover {opacity: 0.7;}

.images {
    display: flex;
    overflow: auto;
}

.pet_data_session_single_image {
    margin-right: 10px;
}

.pet_data_session_single_video {
    margin-right: 10px;
}

.doctor_notes_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
}

.doctor_notes_no_description {
    font-weight: bold;
}

.doctors_notes_save_button {
    color: white;
    background-color: #2AB1C3;
    border-radius: 22px;
    padding: 10px 25px 10px 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.doctors_notes_cancel_button {
    color: #2AB1C3;
    cursor: pointer;
    font-weight: bold;
    margin-right:10%;
}

.doctor_note_dialog_data_div {
    display: flex;
    align-items: center;
    width: 100%;
}

.doctors_notes_dialog_control_box {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right:5%;
}

@media (min-width: 750px) {
    .backbutton_consultid_container {
        display: flex;
    }

    .consult_id_text {
        margin-right: 25%;
        margin-left: auto;
    }

    .main_container {
        padding-top: 50px;
        padding-left: 12%;
        width: 90%;
        height: 80vh;
    }

    .pet_container {
        flex-direction: row;
    }

    .pet_details_container {
        width: 28%;
        margin-right: 30px;
    }

    .pet_details_name {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .pet_details_requested {
        margin-bottom: 10px;
    }

    /* .pet_details_follow_up {
        padding-bottom: 20px;
    } */

    .pet_details_allow_parent_container {
        padding-top:10px;
        padding-bottom: 0px;
    }

    .pet_data_container {
        width: 80%;
        padding-bottom: 0px;
    }

    .pet_container {
        margin-top: 10px;
    }

    .case_completed_button_style {
        margin-top: auto;
    }
}

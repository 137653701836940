.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 91%;
}

.details_name {
    font-weight: light;
    margin-bottom: 5px;
}

.details_value {
    font-size: 18px;
    font-weight: bold;
}

.arrow_button_container {
    display: inline-flex;
    font-weight: bold;
}

.profile_pic {
    height: 70%;
    width: 70%;
}

@media (min-width: 630px) {

    .profile_pic {
        border-radius: 50%;
        width: 70%;
        height:70%;
        margin-right: 2%;
        margin-left: 2%;
    }

    .divider {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .grid-last-row {
        grid-row-start: 4;
        grid-row-end: 4;
    }

    .progress_circle {
        left: 50%;
    }

    .main_container {
        padding-top: 50px;
        padding-left: 15%;
        width: 90%;
    }

    .details_paper {
        border-radius: 6px;
    }

    .paper_container {
        display: flex;
        padding-top: 30px;
        padding-bottom: 120px;
        margin-top: 20px;
        overflow: auto;
        padding-left: 20px;
    }

    .profile_pic_container {
        align-self: center;
        margin-right:1%;
        margin-right: 2%;
        margin-left: 2%;
    }

    .details_grid_container {
        display: grid;
        grid-auto-columns: 340px 340px 340px 340px;
        grid-auto-rows: 60px 60px 60px 60px;
    }



    @media (max-width: 1365px) {
        .main_container {
            padding-top: 50px;
            padding-left: 5%;
            width: 80%;
        }

    }

    @media (min-width: 1570px) {

        .profile_pic_container {
            align-self: center;
            margin-right:5%;
        }

        .paper_container {
            display: flex;
            padding-top: 30px;
            padding-left: 5%;
            padding-bottom: 20px;
            margin-top: 20px;
        }

        .main_container {
            width: 80%;
        }
    }

    @media (max-width: 731px) {
        .main_container {
            padding-top: 50px;
            padding-left: 2%;
            width: 98%;
        }
    }
}

@media (max-width: 630px) {

    .profile_pic {
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 50%;
        width: 120px;
        margin-right: 2%;
        margin-left: 2%;
    }

    .main_container {
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .paper_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .details_value {
        font-size: 18px;
        padding-bottom: 20px;
    }

    .divider {
        padding-bottom: 10px;
    }
}

.empty_img_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty_img_container_text {
    font-weight: bold;
    margin-top: 20px;
}

.caselist_section {
    display: flex;
    flex-direction: column-reverse;
}

.empty_img {
    max-width: 250px;
    height: auto;
}

.available_container {
    display: flex;
    padding-top: 20px;
    padding-left: 5%;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.available_section {
    width: 90%;
    align-self: center;
}

.available_switch_container {
    margin-left: auto;
    padding-right: 5px;
}

.available_text_container_available {
    font-weight: bold;
}

/* @media (max-width: 550px) {
    .available_section {
        width: 100%;
    }
} */

@media (min-width: 800px) {
    .empty_img_container {
        position:absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
    }

    .caselist_section {
        display: flex;
        width: 100%;
        flex-direction: row;
    }
/* 
    .caselist_container {
        width: 70%;
    } */

    .caselist_container {
        width: 100%;
    }

    .available_section {
        margin-left: auto;
        width: 30%;
        align-self: flex-start;
    }

    .available_text_container {
        align-self: center;
        margin-right: 3%;
    }

    .available_text_container_available {
        margin-right: 10px;
        margin-bottom: 1%;
        display: flex;
    }

    .available_switch_container {
        align-self: center;
        margin-left: auto;
        margin-right: 5%;
    }

}